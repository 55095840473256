import { NotificationsService } from './../../../shared/NotificationsService/notifications.service';
import { NotificationMessage, NotificationType } from './../../../entity/NotificationMessage';
import { ApiDocumentsService } from './../../../services/ApiDocuments/api-documents.service';
/* eslint-disable dot-notation */
import { BankInformation } from './../../../entity/BankInformation';
import { ApiAdminBpmService } from 'src/app/services/ApiAdminBpm/api-admin-bpm.service';
import { Router, ActivatedRoute } from '@angular/router';
import { InformationsSpecifique } from './../../../entity/Opportunity/InformationsSpecifique';
import { InformationsComplmentaire } from './../../../entity/Opportunity/InformationsComplmentaire';
import { ApiOpportunitService } from './../../../services/ApiOpportunite/api-opportunite.service';
import { ApiStatusService } from './../../../services/ApiStatus/api-status.service';
import { StatusAutorise, StatutChild } from './../../../entity/Opportunity/StatusAutorise';
import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';

import { tap, catchError, debounceTime } from 'rxjs/operators';
import { AffaireDetails } from 'src/app/entity/Affaires/AffaireDetails';
import { ApiAffairesService } from 'src/app/services/ApiAffaires/api-affaires.service';
import { ApiProspectsService } from 'src/app/services/ApiProspects/api-prospects.service';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import swal from 'sweetalert2';
import { Prospect } from '../../../entity/Prospect';
import { Entreprise } from '../../../entity/Opportunity/Entreprise';
import { ApiComminucationService } from 'src/app/services/ApiComminucation/api-comminucation.service';
import { HistoriqueComponent } from './historique/historique.component';
import { Subject } from 'rxjs-compat/Subject';
import { Observable } from 'rxjs-compat/Observable';
import { ListTypesAndParent } from 'src/app/entity/Opportunity/ListTypesAndParent';
import { HistoriquesAffaires } from 'src/app/entity/Affaires/HistoriquesAffaires';
import { InformationAvanceesComponent } from './information-avancees/information-avancees.component';
import { ApiAuthentificationService } from 'src/app/services/ApiAuthentification/api-authentification.service';
import { AccessList } from 'src/app/entity/accessList';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { StatusGarantieComponent } from './status-garantie/status-garantie.component';
import { EventEmiterService } from 'src/app/services/event.emmiter.service';
import { FormControl, Validators } from '@angular/forms';
import { element } from 'protractor';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Motif } from '../../parametres/gestion-status-affaire/fiche-status-affaire/fiche-status-affaire.component';
import { Process } from 'src/app/entity/Process';
import { DatePipe } from '@angular/common';
import { Subscription, forkJoin, of } from 'rxjs';
import { isEqual, differenceWith } from 'lodash';
import { HistoryService } from './history.service';
import { UniformiserDateToLocalSansHeure } from 'src/app/shared/Utils/sharedFunctions';
import { TransactionFinanciereComponent } from './transaction-financiere/transaction-financiere.component';
import { EcheancierComponent } from './echeancier/echeancier.component';
import { FormbuilderValidationService } from 'src/app/services/formbuilder-validation.service';
class GarantieAffaire {
  id_aff: string;
  garanties: any = [];
}
class Avenant {
  id_affaire: any;
  motif: any;
  commentaire: any;
}

export class BodyAction {
  id_affaire;
  id_statut;
  commentaire_action;
  id_motif;
}
export class MotifAffaire {
  id_affaire: any;
  id_motif: any;
  commentaire_action?: any;
}
class TypeErrorAffaire {
  info_banc: string = '';
  info_affaire: string = '';
  info_specifique: string = '';
  entreprise: string = '';
  garantie_affaire: string = '';
  objet_assure: string = '';
  infoDoc: string = '';
  quality_affaire: string = '';
}
@Component({
  selector: 'app-affaire-details',
  templateUrl: './affaire-details.component.html',
  styleUrls: ['./affaire-details.component.css'],
})
export class AffaireDetailsComponent implements OnInit, AfterViewInit, OnChanges {
  affaire_id: string = '';
  id_opportunite: string = '';
  prospect_id: string = '';
  contrat_id: string = '';
  private _listners = new Subject<any>();
  entreprise: Entreprise = null;
  societe: Entreprise = new Entreprise();
  entrepriseUpdated: boolean = false;
  contratUpdated: boolean = false;
  contratHistorique: boolean = false;
  contratInfoBancaire: boolean = false;
  contratuploadDocument: boolean = false;
  infoSpecifiqueUpdated: boolean = false;
  infoComplementaireUpdated: boolean = false;
  successChange: boolean = false;
  isListe: boolean = false;
  currency: string = environment.currentCurrency;
  formatCurrency: string = environment.formatCurrency;

  idSelected: any;
  ListIdProspect = [];
  item: number = 0;
  Actions = 'Changer le statut';
  Enregistrer = 'Mettre à jour';
  affaireDetailsUpdate: AffaireDetails = new AffaireDetails();
  // infoContrat: AffaireDetails;
  telNumber: any;
  prospect = new Prospect();
  status_autorise: StatusAutorise = new StatusAutorise();
  show_loading: boolean = false;
  show_loading_status: boolean = false;
  lastActionId: string = '';
  subStatusList: StatutChild[] = [];
  commentChangeAction: string = '';
  status: string = '';
  show_loadingAffaireComm: boolean;
  @ViewChild(HistoriqueComponent) childhisto: HistoriqueComponent;
  @ViewChild(InformationAvanceesComponent) informationAvanceesComponent: InformationAvanceesComponent;
  @ViewChild('transactionFinance') transactionFinance: TransactionFinanciereComponent;
  @ViewChild('Echeancier') Echeancier: EcheancierComponent;
  affaireData: string = '';
  informationsContrat: AffaireDetails = new AffaireDetails();
  infoComplementaire: InformationsComplmentaire;
  infoSpecifique: InformationsSpecifique;
  mobileProspect: string = '';
  mailProspect: string = '';
  sum: number = 0;
  jsonObject = {};
  listParent: ListTypesAndParent[] = [];
  listTypes: ListTypesAndParent[] = [];
  type_envoie: any;
  statut_envoi: any;
  statut: any;
  formInformationSpecifique: any;
  ProspectExport: any;
  Historiquesexport: HistoriquesAffaires[] = [];
  oldBankInformation: BankInformation[] = [];
  status_id: any;
  allowed_to_update_affaire: boolean;
  formDataUploadDocument: any = [];
  accessList: AccessList;
  ecriture_transaction_financiere_client: boolean;
  lecture_transaction_financiere_client: boolean;
  histAccess: boolean;
  histCommAccess: boolean;
  listeGarantieAffaire: any;
  CA: string;
  listGrantie: GarantieAffaire[] = [];
  exist: boolean = false;
  upadateGarantieAffaire: boolean = false;
  upadateobjetAssureAffaire: boolean = false;
  bodyGarantie: GarantieAffaire = new GarantieAffaire();
  listObjetAssurer: any[] = [];
  access_categories_aff: any[] = [];
  bien: boolean = false;
  person: boolean = false;
  errorProspect: boolean = false;
  info_bancaire: BankInformation[];
  listError: TypeErrorAffaire = new TypeErrorAffaire();
  appear = true;
  listMotif: any[] = [];
  idStatut: any;
  acces_avenant: boolean;
  alerts: any;
  buttons: any;
  navbarTxt: string = '';
  access_qualite: boolean;
  listOfProcess: Process[] = [];
  modifiedArray: any[] = [];
  numContrat: string = '';
  quality_body: any;
  detect_change_quality: boolean = false;
  transformDateDeff: string;
  pasMotif: any = {};
  noMotif: any;
  loaderMontant: boolean;
  isFormBuilderValid: any;
  invalidFields: string[];
  RedirectUrl: boolean;
  addAvenantLoader: boolean;
  updateStatus: boolean = false;
  updateStatusaffaire: boolean = false;
  balance: any ="";
  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private apiProspectService: ApiProspectsService,
    private apiAffairesService: ApiAffairesService,
    private apiOpportuniteServices: ApiOpportunitService,
    private apiProspectsService: ApiProspectsService,
    private sharedMenuObserverService: SharedMenuObserverService,
    private apiStatusService: ApiStatusService,
    private apiCommunicationService: ApiComminucationService,
    private apiOpportunitService: ApiOpportunitService,
    private apiAdminBpmServices: ApiAdminBpmService,
    private apiDocumentsService: ApiDocumentsService,
    private notificationsService: NotificationsService,
    private HistoryService: HistoryService,
    private changeDetector: ChangeDetectorRef,
    private apiAuthentificationService: ApiAuthentificationService,
    private _eventEmiter: EventEmiterService,
    private router: Router,
    private modalService: NgbModal,
    private translate: TranslateService,
    private validationService: FormbuilderValidationService,

    private datePipe: DatePipe
  ) {
    this.listennumber().subscribe((m: any) => {
      this.sum = this.sum + m;
      if (this.sum > 0) {
        if (this.successChange === true) {
          // this.alertSuccess('Affaire modifiée avec succée');
          this.successChange = false;
        } else this.errorInfo();

        this.sum = 0;
        this.childhisto?.getHistorique();
        this.hideloader();
      }
    });

    this.apiDocumentsService.uploadDocument().subscribe((formDataDocuments: FormData) => {
      this.formDataUploadDocument.push(formDataDocuments);
      const lastItemsDict: { [key: string]: any } = {};
      this.modifiedArray = [];
      this.formDataUploadDocument.forEach((item) => {
        const keys = item.Formdata.keys();

        for (const key of keys) {
          if (key === 'file[]') {
            lastItemsDict[item.documentId] = item;
          }
        }
      });

      this.modifiedArray = Object.values(lastItemsDict);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.apiAuthentificationService.listOfAccess$.subscribe((value) => {
      this.accessList = value;
      this.ecriture_transaction_financiere_client = this.accessList.ecriture_transaction_financiere_client;
      this.lecture_transaction_financiere_client = this.accessList.lecture_transaction_financiere_client;
      this.histAccess = this.accessList.historique;
      this.histCommAccess = this.accessList.historique_communications;
      this.acces_avenant = this.accessList.acces_avenant;
      this.access_categories_aff = this.accessList.access_categories_aff;
      this.access_qualite = this.accessList.acces_qualite;
    });
  }

  ngAfterViewInit(): void {
    this.allowed_to_update_affaire = this.informationsContrat.contrat.allowed_to_update;
  }

  getlistSpecif($event: any) {
    const jsonO = [];
    let test = false;
    const doc = $event;

    Object.keys(doc).forEach(function (value) {
      if (value === 'inf_prod') delete doc[value];
    });

    Object.assign($event, doc);
    // Object.assign(jsonO, this.jsonObject);
    jsonO.forEach((element, index) => {
      Object.keys(element).forEach(function (value) {
        if (value === 'Infomation_Specific') {
          jsonO.splice(index, 1);
          test = true;
          jsonO['Infomation_Specific'] = $event;

          // jsonO.push({ InfoSpecific: $event });
        }
      });
    });
    if (!test) jsonO['Infomation_Specific'] = $event;
    // Object.assign(this.jsonObject, jsonO);
  }

  getlistComp($event: any) {
    const jsonO = [];
    let test = false;
    const doc = $event;
    Object.keys(doc).forEach(function (value) {
      if (value === 'infcamp') delete doc[value];
    });

    Object.assign($event, doc);
    // Object.assign(jsonO, this.jsonObject);
    jsonO.forEach((element, index) => {
      Object.keys(element).forEach(function (value) {
        if (value === 'Informations_Complementaire') {
          jsonO.splice(index, 1);
          test = true;
          jsonO['Informations_Complementaire'] = $event;

          // jsonO.push({ InformationsComplmentaire: $event });
        }
      });
    });
    if (!test) jsonO['Informations_Complementaire'] = $event;

    // Object.assign(this.jsonObject, jsonO);
  }

  getListDocOpp($event: any) {
    if ($event.length > 0) {
      const jsonO = [];
      let test = false;
      const doc = $event;

      Object.assign($event, doc);
      // Object.assign(jsonO, this.jsonObject);
      jsonO.forEach((element, index) => {
        Object.keys(element).forEach(function (value) {
          if (value === 'List_Document_Opportunité') {
            jsonO.splice(index, 1);
            test = true;
            jsonO['List_Document_Opportunité'] = $event;

            // jsonO.push({ ListDocumentOpportunité: $event });
          }
        });
      });
      if (!test) jsonO['List_Document_Opportunité'] = $event;
      // Object.assign(this.jsonObject, jsonO);
    }
  }

  getlistContratenliason($event) {
    if ($event.length > 0) {
      // this.jsonObject['list_Affaire_En_Relation'] = $event;
    }
  }

  getListDoc($event: any) {
    if ($event.length > 0) {
      const jsonO = [];
      let test = false;
      const doc = $event;

      Object.assign($event, doc);
      // Object.assign(jsonO, this.jsonObject);
      jsonO.forEach((element, index) => {
        Object.keys(element).forEach(function (value) {
          if (value === 'List_Document') {
            jsonO.splice(index, 1);
            test = true;
            jsonO['List_Document'] = $event;

            // jsonO.push({ ListDocument: $event });
          }
        });
      });
      if (!test) jsonO['List_Document'] = $event;

      // Object.assign(this.jsonObject, jsonO);
    }
  }

  getlistecheancier($event) {
    if ($event.length > 0) {
      // this.jsonObject['list_echancier'] = $event;
    }
  }

  getlistpaimentfrais($event) {
    if ($event.length > 0) {
      const jsonO = [];
      let test = false;
      // Object.assign(jsonO, this.jsonObject);
      jsonO.forEach((element, index) => {
        Object.keys(element).forEach(function (value) {
          if (value === 'frais_de_paiement') {
            jsonO.splice(index, 1);
            test = true;
            jsonO['frais_de_paiement'] = $event;
            // jsonO.push({ paimentfrais: $event });
          }
        });
      });
      if (!test) jsonO['Statut_actuel'] = $event;
      // Object.assign(this.jsonObject, jsonO);
    }
  }

  /**
   *
   * @param bankInformation
   */

  getBankInformation(bankInformation: BankInformation[]): void {
    Object.assign(this.oldBankInformation, bankInformation);
    // this.successChange = true;
    if (this.oldBankInformation !== this.informationsContrat.info_bancaire) {
      this.info_bancaire = bankInformation;
      this.info_bancaire.forEach((element: BankInformation) => {
        if (element.iban) element.iban = element.iban.replace(/\s/g, '');
      });
    }
  }

  changedetectInfo(event) {
    this.contratInfoBancaire = event;
    this.listError.info_banc = '';
  }

  getlistcommentaireexport($event) {
    if ($event.length > 0) {
      const jsonO = [];
      let test = false;
      // Object.assign(jsonO, this.jsonObject);
      jsonO.forEach((element, index) => {
        Object.keys(element).forEach(function (value) {
          if (value === 'List_Commentaire') {
            jsonO.splice(index, 1);
            test = true;
            jsonO['List_Commentaire'] = $event;
            // jsonO.push({ ListCommentaire: $event });
          }
        });
      });
      if (!test) jsonO['List_Commentaire'] = $event;
      // Object.assign(this.jsonObject, jsonO);
    }
  }

  getlistticket($event) {
    if ($event.length > 0) {
      // this.jsonObject['List_Tickets_Affaires'] = $event;
    }
  }

  changeLang() {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
        this.Enregistrer = this.buttons.enregistrer;
        this.navbarTxt = object.listing.Affaire + ' ➜ ' + object.navbar.Détails;
        this.sharedMenuObserverService.updateMenu(this.navbarTxt);
      });
    });
  }

  ngOnInit(): void {
    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
      this.buttons = object.buttons;
      this.Enregistrer = this.buttons.enregistrer;
      this.noMotif = object.affaire.noMotif;
      this.navbarTxt = object.listing.Affaire + ' ➜ ' + object.navbar.Détails;
      this.sharedMenuObserverService.updateMenu(this.navbarTxt);
    });
    this.changeLang();
    this.validationService.isFormValid$.subscribe((isValid) => {
      this.isFormBuilderValid = isValid;
    });
    this.validationService.invalidFields$.subscribe((invalidFields) => {
      this.invalidFields = invalidFields;
    });
    this.apiAuthentificationService.listOfAccess$.subscribe((value) => {
      this.accessList = value;
      this.ecriture_transaction_financiere_client = this.accessList.ecriture_transaction_financiere_client;
      this.lecture_transaction_financiere_client = this.accessList.lecture_transaction_financiere_client;
      this.histAccess = this.accessList.historique;
      this.histCommAccess = this.accessList.historique_communications;
      this.acces_avenant = this.accessList.acces_avenant;
      this.access_categories_aff = this.accessList.access_categories_aff;
      this.access_qualite = this.accessList.acces_qualite;
    });
    this.showloader();

    this.route.paramMap.subscribe((params) => {
      this.affaire_id = params.get('affaire_id');
      this.getInformationsContrat();
      this.apiAffairesService.getlistBpmAffaire(this.affaire_id).subscribe((data) => {
        this.listOfProcess = data;
      });
    });
    this.apiAffairesService.listenLiaisonsContracts().subscribe((data: any) => {
      this.getInformationsContrat();
    });
    // this.getAffaireGarantie();
    this._eventEmiter.getListObject().subscribe((data) => {
      this.listObjetAssurer = data;
    });
  }

  expandNumContrat(name: string): string {
    name = name?.trim();
    if (name?.length < 30) {
      return name;
    } else {
      return name?.substring(0, 30) + '...';
    }
  }

  getListTypeAndParent() {
    this.apiCommunicationService
      .GetListTypeAndParent()
      .pipe(
        tap(({ parent, types }) => {
          this.listParent = parent;
          this.listTypes = types;
        })
      )
      .subscribe();
  }

  getDetailsLibelleCourrier() {
    this.apiCommunicationService
      .GetDetailsLibellePost()
      .pipe(
        tap(({ statut, statut_envoi, type_envoie }) => {
          this.statut = statut;
          this.statut_envoi = statut_envoi;
          this.type_envoie = type_envoie;
        })
      )
      .subscribe();
  }

  getTelOpp() {
    this.apiOpportuniteServices.getProspectTelByOppId(this.id_opportunite).subscribe((data) => {
      this.telNumber = data;
    });
  }

  /*   getProspectTel() {
      this.apiProspectService.getProspectByOpprtuniteId(this.id_opportunite).subscribe((data) => {
        this.prospect = data;
       // this.societe = data;
      });
    }
   */
  getProspect(idopp: string) {
    this.apiProspectService.getProspectByOpprtuniteId(idopp).subscribe(
      (data) => {
        this.societe = data;
        this.prospect = data;
        this.hideloader();
      },
      (erro) => {
        this.hideloader();
      }
    );
  }

  reloadContrat($event) {
    if ($event === true) {
      this.getInformationsContrat();
      this.transactionFinance.GetTransactionsAffaire();
      this.changeDetector.detectChanges();
    }
  }

  getInformationsContrat() {
    this.show_loadingAffaireComm = true;
    this.loaderMontant = true;
    this.apiAffairesService.getAffaireInformationsContrat(this.affaire_id).subscribe(
      (data: any) => {
        this.informationsContrat = data;
        this.numContrat = this.informationsContrat?.contrat?.num_contrat
          ? this.informationsContrat.contrat.num_contrat
          : this.informationsContrat.contrat.num_affaire;

        this.numContrat += this.informationsContrat?.contrat?.version
          ? ' (' + this.informationsContrat.contrat.version.toString() + ')'
          : '';

        this.numContrat += this.informationsContrat?.contrat?.origin
          ? ' - ' + this.informationsContrat.contrat.origin
          : '';

        this.numContrat += this.informationsContrat?.contrat?.libelle_motif_avenant
          ? ' - ' + this.informationsContrat.contrat.libelle_motif_avenant
          : '';
        /// this.getMotifByStatutActuel()
        this.balance=this.informationsContrat?.montant_echeancier
        if (this.informationsContrat.contrat.bien === 1) {
          this.bien = true;
        } else {
          this.bien = false;
        }
        if (this.informationsContrat.contrat.person === 1) {
          this.person = true;
        } else {
          this.person = false;
        }

        this.allowed_to_update_affaire = this.informationsContrat.contrat.allowed_to_update;
        this.status = this.informationsContrat.contrat.status;
        this.status_id = this.informationsContrat.contrat.etat_dossier_id;
        this.contrat_id = data.contrat.id_contrat;
        this.id_opportunite = data.contrat.id_opp_md5;
        this.prospect_id = data.contrat.id_prospect.toString();

        this.transformDateDeff = UniformiserDateToLocalSansHeure(data.contrat.date_deff);
        this.getListTypeAndParent();
        this.getDetailsLibelleCourrier();
        // this.getProspectTel();
        this.getTelOpp();
        this.getProspect(data.contrat.id_opp_md5);
        // this.jsonObject['Statut_actuel'] = this.informationsContrat.contrat.status;
        // this.jsonObject['status_id'] = this.informationsContrat.contrat.etat_dossier_id;
        // this.jsonObject['ligne_de_produit'] = this.informationsContrat?.contrat.type_contrat;
        this.getcontratexport(this.informationsContrat);
        this.show_loadingAffaireComm = false;
        this.loaderMontant = false;
      },
      (error) => {
        // this.hideloader()
        this.openErrorAffaire(error?.error?.message);
      }
    );
  }

  openErrorAffaire(data) {
    this.changeDetector.detectChanges();

    swal
      .fire({
        title: this.alerts.error,
        text: data,
        icon: 'warning',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: this.buttons.fermer,
        confirmButtonColor: '#d53a3a',
        customClass: {
          popup: 'border-radius-0',
        },
      })
      .then((result) => {
        if (result.isConfirmed || result.isDismissed) {
          this.router.navigate(['/affaires']);
        }
      });
  }

  extractDate(inputDate) {
    if (inputDate) {
      const dateObject = new Date(inputDate);
      return this.datePipe.transform(dateObject, 'dd-MM-yyyy');
    }
  }

  existeDansListeSup: boolean = false;
  existeVisa: boolean = false;
  getStatusAutorise() {
    this.appearModel = true;
    this.appear = true;
    this.show_loading_status = true;
    this.apiStatusService
      .getStatutAutoriseAffaireGet(this.affaire_id)
      .pipe(
        tap(({ listactions }) => {
          this.status_autorise.listCategory = listactions;
          this.status_autorise.listCategory.forEach((element) => {
            this.show_loading_status = false;
            element.listactions.forEach((statut) => {
              this.existeDansListeSup = this.access_categories_aff.some((item) => item === element.id);
              if (this.existeDansListeSup === false && statut.sup === 1) {
                statut.access_sup = false;
              } else {
                statut.access_sup = true;
              }
              //  }
            });
          });
        })
      )
      .subscribe((data) => {
        this.show_loading_status = false;
      });
  }

  async toggleupdate() {
    if (this.updateStatus) {
      this.changeAction();
    } else {
      this.updateAffaire();
    }
  }

  showModalUpdateStatut() {
    this.updateStatus = true;
    this.updateStatusaffaire = false;
    this.appear = false;
    this.appearModel = true;
    this.show_loading_status = true;
    this.apiStatusService
      .getStatutAutoriseAffaireGet(this.affaire_id)
      .pipe(
        tap(({ listactions }) => {
          this.status_autorise.listCategory = listactions;
          this.status_autorise.listCategory.forEach((element) => {
            this.show_loading_status = false;
            element.listactions.forEach((statut) => {
              this.existeDansListeSup = this.access_categories_aff.some((item) => item === element.id);
              if (this.existeDansListeSup === false && statut.sup === 1) {
                statut.access_sup = false;
              } else {
                statut.access_sup = true;
              }
              //  }
            });
          });
        })
      )
      .subscribe((data) => {
        this.show_loading_status = false;
      });
  }

  appearS() {
    this.step = true;
  }

  step: boolean = false;
  existeDansListeSupChild: boolean = false;
  updateSubActionsList(id: string) {
    this.show_loading = true;
    this.lastActionId = id;
    this.liste_motif = [];
    this.apiStatusService.getListActionsAffaireGet(id).subscribe(
      (data) => {
        if (data.length !== 0) {
          this.subStatusList = data.class_etas_list;
          this.liste_motif = [];
          this.subStatusList.forEach((element) => {
            this.existeDansListeSupChild = this.access_categories_aff.some((item) => item === element.categorie);
            if (this.existeDansListeSupChild === false && element.sup === 1) {
              element.access_sup = false;
            } else {
              element.access_sup = true;
            }
          });

          this.show_loading = false;
        } else {
          this.subStatusList = [];
          this.apiAdminBpmServices.getListMotifStatut(id).subscribe((response) => {
            this.liste_motif = response.Motifs;
            if (this.liste_motif.length === 0) {
              this.show_massage = true;
            } else {
              this.show_massage = false;
              this.pasMotif.active = 1;
              this.pasMotif.background = '#bc2525';
              this.pasMotif.color = '#FFF';
              this.pasMotif.libelle = this.noMotif;

              this.liste_motif.push(this.pasMotif);
            }

            this.loaderMotif = false;
          });
          this.show_loading = false;
        }
      },
      (error) => {
        console.log(error);
        this.show_loading = false;
      }
    );
  }

  id_motif: any = '';
  changeAction() {
    this.show_loading = true;
    this.appearModel = false;
    if (!this.id_motif) this.id_motif = '';
    const bodyAction: BodyAction = new BodyAction();
    bodyAction.commentaire_action = this.commentChangeAction;
    bodyAction.id_affaire = this.affaire_id;
    bodyAction.id_motif = this.id_motif;
    bodyAction.id_statut = this.lastActionId;
    this.showloader();
    this.apiOpportunitService.updateaffaireAction(bodyAction).subscribe(
      () => {
        this.hideloader();
        if (this.informationsContrat.contrat.laison_contrat.length > 0) {
          const x = this.informationsContrat.contrat.laison_contrat.length;
          this.alertWarnningAction(x);
        } else {
          this.alertSuccess(this.alerts.updatedActionSucess);
        }

        // this.Actualise();
        this.show_loading = false;

        // this.Actualise();
      },
      (error) => {
        this.hideloader();
        this.alertErrorAction(this.alerts.error, error.error.message);

        this.show_loading = false;
      }
    );
  }

  show_massage: boolean = false;
  getMotif(id) {
    this.show_massage = true;
    this.id_motif = id;
  }

  listennumber(): Observable<any> {
    return this._listners.asObservable();
  }

  pushnumber(filterBy: any) {
    this._listners.next(filterBy);
  }

  notificationDialog(type: number, title: string, message: string, time: number): void {
    const notificationMessage = new NotificationMessage();
    notificationMessage.type = type;
    notificationMessage.title = title;
    notificationMessage.message = message;
    notificationMessage.timeOut = 5000 + 2000 * time;
    this.notificationsService.sendMessage(notificationMessage);
  }

  getQualityData(event) {
    this.quality_body = event;
    // this.detect_change_quality = true;
    this.listError.quality_affaire = '';
  }

  detectChangeQuality(event) {
    this.detect_change_quality = event;
  }

  async updateAffaire() {
    let subscriptionsToFork: any[] = [];
    if (this.errorProspect === false) {
      // To upload affaire documents
      if (
        this.modifiedArray.length > 0 ||
        this.contratInfoBancaire ||
        this.entrepriseUpdated ||
        this.infoSpecifiqueUpdated ||
        this.upadateGarantieAffaire ||
        this.upadateobjetAssureAffaire ||
        this.contratUpdated ||
        this.detect_change_quality ||
        this.updateStatus
      ) {
        this.showloader();
        if (this.modifiedArray.length > 0) {
          this.modifiedArray.forEach((element) => {
            subscriptionsToFork.push(
              this.apiDocumentsService.UploadDocuments(element.Formdata).pipe(
                catchError((error) => {
                  return error; // Return an empty observable to allow zip to continue with the next observable
                })
              )
            );
          });
        }
        // To update bank informations
        if (this.contratInfoBancaire) {
          const hasEmptyFields = this.info_bancaire.some((item) => {
            return (
              item.titulaire_compte === '' ||
              item.iban === '' ||
              item.bic_swift === '' ||
              item.type_iban === '' ||
              item.nom_banque === ''
            );
          });
          if (hasEmptyFields) {
            this.listError.info_banc = this.alerts.fillObligInfoBanc;
          } else if (
            this.info_bancaire.length === 2 &&
            this.info_bancaire[0].type_iban === this.info_bancaire[1].type_iban
          ) {
            this.listError.info_banc = this.alerts.chooseDiffTypeIban;
          } else {
            subscriptionsToFork.push(
              this.apiAffairesService
                .UpdateInfoBancaire(this.affaire_id, this.info_bancaire)
                .pipe(catchError((error) => of({ error })))
            );
          }
        }
        if (this.entrepriseUpdated) {
          // this.entreprise.json_object = JSON.parse(JSON.stringify(this.jsonObject));
          if (!this.isFormBuilderValid) {
            this.alertWarningInfoComp(this.alerts.verifyInfoComp);
          } else {
            subscriptionsToFork.push(
              this.apiProspectsService
                .UpdateEntreprise(this.affaire_id, this.entreprise, this.id_opportunite)
                .pipe(catchError((error) => of({ error })))
            );
          }
        }
        if (this.infoSpecifiqueUpdated) {
          // this.infoSpecifique.json_object = JSON.parse(JSON.stringify(this.jsonObject));
          if (!this.isFormBuilderValid) {
            this.alertWarningInfoComp(this.alerts.verifyInfoComp);
          } else {
            subscriptionsToFork.push(
              this.apiOpportunitService
                .updateInformationsSpecifique(this.affaire_id, this.infoSpecifique)
                .pipe(catchError((error) => of({ error })))
            );
          }
        }
        if (this.upadateGarantieAffaire) {
          subscriptionsToFork.push(
            this.apiAffairesService.updateAffaireGarantie(this.bodyGarantie).pipe(catchError((error) => of({ error })))
          );
        }
        if (this.upadateobjetAssureAffaire) {
          if (this.listObjetAssurer.length > 0) {
            if (!this.isFormBuilderValid) {
              this.alertWarningInfoComp(this.alerts.verifyInfoComp);
            } else {
              const nouvelleListe = this.listObjetAssurer.map((element) => {
                return {
                  [element.nom]: { index: element.index, data: element.form.data },
                };
              });
              subscriptionsToFork.push(
                this.apiOpportunitService
                  .update_objet_assure_by_entity(this.affaire_id, 'affaire', nouvelleListe)
                  .pipe(catchError((error) => of({ error })))
              );
            }
          } else {
            this.hideloader();
            this.alertError(this.alerts.chooseObjectAssure);

            this.show_loading = false;
          }
        }
        if (this.contratUpdated) {
          // this.affaireDetailsUpdate = this.infoContrat;
          this.affaireDetailsUpdate.contrat.id_prospect = this.prospect_id;
          this.showloader();
          // this.affaireDetailsUpdate.json_object = JSON.parse(JSON.stringify(this.jsonObject));
          subscriptionsToFork.push(
            this.apiAffairesService
              .UpdateAffaire(this.affaire_id, this.affaireDetailsUpdate)
              .pipe(catchError((error) => of({ error })))
          );
        }
        if (this.detect_change_quality) {
          if (!this.isFormBuilderValid) {
            this.alertWarningInfoComp(this.alerts.verifyInfoComp);
          } else {
            subscriptionsToFork.push(
              this.apiAffairesService
                .UpdateQualityAffaire(this.affaire_id, this.quality_body)
                .pipe(catchError((error) => of({ error })))
            );
          }
        }

        forkJoin(subscriptionsToFork).subscribe(
          (results) => {
            console.log(this.updateStatus);
            if (this.updateStatusaffaire) {
              this.changeAction();
            }
            this.hideloader();
            results.forEach((element: any, index: number) => {
              if (element?.error)
                this.notificationDialog(
                  NotificationType.error,
                  this.alerts.failedOperation,
                  element?.error?.error?.message || this.alerts.problemservenuAffaire,
                  index + 1
                );
              else {
                this.notificationDialog(
                  NotificationType.success,
                  this.alerts.Successoperation,
                  element?.message,
                  index + 1
                );
              }
            });
            this.HistoryService.sendFetchDataSignal(true);
            if (this.modifiedArray.length > 0) {
              this.apiDocumentsService.loadDocument$.next('refreshAffaire');
            }
            if (this.contratUpdated) {
              this.getInformationsContrat();
            }
            subscriptionsToFork = [];
            this.contratUpdated = false;
            this.upadateobjetAssureAffaire = false;
            this.upadateGarantieAffaire = false;
            this.infoSpecifiqueUpdated = false;
            this.entrepriseUpdated = false;
            this.contratInfoBancaire = false;
            this.modifiedArray = [];
            this.formDataUploadDocument = [];
            this.detect_change_quality = false;
            this.validationService.resetFieldValidity();
          },
          (error) => {
            this.notificationDialog(
              NotificationType.error,
              this.alerts.failedOperation,
              error?.error?.message || this.alerts.problemservenuAffaire,
              2
            );

            this.hideloader();
          }
        );
      } else {
        
        if (this.updateStatusaffaire) {
          this.changeAction();
        }else{
          swal.fire({
            title: this.alerts.noModif,
            confirmButtonText: this.buttons.annuler,
            icon: 'info',
          });
        }
      }
    } else {
      this.hideloader();
      this.alertError(this.alerts.replirinfoAdherent);
      this.show_loading = false;
    }
  }

  getError($event) {
    this.errorProspect = $event;
  }

  uploadDocumentsAffaire(formData: FormData): Subscription {
    this.successChange = false;
    return this.apiDocumentsService.UploadDocuments(formData).subscribe(
      (response: any) => {
        this.listError.infoDoc = '';
        this.apiAffairesService.pushdocumenthistorique('test');
      },
      (error) => {
        this.successChange = false;
        this.listError.infoDoc = error.message;
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = this.alerts.Operationechoue;
        notificationMessage.message = error.message;
        this.notificationsService.sendMessage(notificationMessage);
      }
    );
  }

  alertWarningInfoComp(data) {
    const invalidFieldsList = this.invalidFields.map((field) => `<li>${field}</li>`).join('');
    const alertContent = `
  
    <ul>${invalidFieldsList}</ul>
  `;
    this.hideloader();
    swal.fire({
      title: data,
      icon: 'warning',
      html: alertContent,
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#e75e5e',
    });
  }

  getcontratexport($event: AffaireDetails) {
    const jsonO = [];
    let test = false;
    // Object.assign(jsonO, this.jsonObject);
    jsonO.forEach((element, index) => {
      Object.keys(element).forEach(function (value) {
        if (value === 'informations_Contrat') {
          jsonO.splice(index, 1);
          test = true;
          jsonO['informations_Contrat'] = $event;

          // jsonO.push({ informationsContrat: $event });
        }
      });
    });
    if (!test) jsonO['informations_Contrat'] = $event;
    // Object.assign(this.jsonObject, jsonO);
  }

  onUpdateContrat($event: AffaireDetails) {
    this.affaireDetailsUpdate = $event;
    this.contratUpdated = true;
    this.listError.info_affaire = '';
    this.getcontratexport($event);
  }

  submitInfoCompl($event: any) {
    this.infoComplementaire = $event;
    this.infoComplementaireUpdated = true;
    const jsonO = [];
    let test = false;
    const doc = $event;
    Object.keys(doc).forEach(function (value) {
      if (value === 'infcamp') delete doc[value];
    });

    Object.assign($event, doc);
    // Object.assign(jsonO, this.jsonObject);
    jsonO.forEach((element, index) => {
      Object.keys(element).forEach(function (value) {
        if (value === 'Informations_Complementaire') {
          jsonO.splice(index, 1);
          test = true;
          jsonO['Informations_Complementaire'] = $event;

          // jsonO.push({ InformationsComplmentaire: $event });
        }
      });
    });
    if (!test) jsonO['Informations_Complementaire'] = $event;
    // Object.assign(this.jsonObject, jsonO);
  }

  submitInfoSpecifique($event: any) {
    this.infoSpecifique = $event;
    this.listError.info_specifique = '';
    this.infoSpecifiqueUpdated = true;
    const jsonO = [];
    let test = false;
    const doc = $event;
    Object.keys(doc).forEach(function (value) {
      if (value === 'inf_prod') delete doc[value];
    });

    Object.assign($event, doc);
    // Object.assign(jsonO, this.jsonObject);
    jsonO.forEach((element, index) => {
      Object.keys(element).forEach(function (value) {
        if (value === 'Infomation_Specific') {
          jsonO.splice(index, 1);
          test = true;
          jsonO['Infomation_Specific'] = $event;
          // jsonO.push({ InfoSpecific: $event });
        }
      });
    });
    if (!test) jsonO['Infomation_Specific'] = $event;
    // Object.assign(this.jsonObject, jsonO);
  }

  isObjectEqual(event, entreprise) {
    return isEqual(event, entreprise);
  }

  onUpdateSociete($event: Entreprise) {
    this.entrepriseUpdated = true;
    this.validationService.isFormValid$.subscribe((isValid) => {
      this.isFormBuilderValid = isValid;
    });
    this.validationService.invalidFields$.subscribe((invalidFields) => {
      this.invalidFields = invalidFields;
    });
    this.entreprise = $event;
    this.listError.entreprise = '';
  }

  alertSuccess(data) {
    swal
      .fire({
        title: this.alerts.Successoperation,
        text: data,
        icon: 'success',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: this.buttons.fermer,
        confirmButtonColor: '#68a45b',
      })
      .then((result) => {
        if (result.isConfirmed || result.isDismissed) this.ngOnInit();
        this.motif = '';
        this.commentaire = '';
        this.Actualise();
      });
  }

  alertSuccessAction(data) {
    swal
      .fire({
        title: this.alerts.Successoperation,
        text: '',
        icon: 'success',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: this.buttons.fermer,
        confirmButtonColor: '#68a45b',
      })
      .then((result) => {
        if (result.isConfirmed || result.isDismissed) {
          if (this.informationsContrat.contrat.laison_contrat.length > 0) {
            const x = this.informationsContrat.contrat.laison_contrat.length;
            this.alertWarnningAction(x);
            this.subStatusList = [];
          } else {
            this.ngOnInit();
            this.motif = '';
            this.commentaire = '';
            this.Actualise();
          }
        }
      });
  }

  listErrorAction: any[] = [];
  listSuccessAction: any[] = [];
  listErrorAction2: any[] = [];
  listSuccessAction2: any[] = [];

  async alertWarnningAction(nbAffaire) {
    let message: string;
    if (nbAffaire === 1) {
      message = this.alerts.yavoir + this.alerts.uneAffaire + this.alerts.wantUpdateAffaire;
    } else {
      message = this.alerts.yavoir + nbAffaire + this.alerts.affaires + this.alerts.wantUpdateAffaire;
    }

    const result = await swal.fire({
      title: this.alerts.affaireLiee,
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.buttons.Ignorer,
      confirmButtonText: this.alerts.Oui,
    });

    if (result.isConfirmed) {
      const bodyAction: BodyAction = new BodyAction();
      bodyAction.commentaire_action = this.commentChangeAction;
      this.id_motif ? (bodyAction.id_motif = this.id_motif) : (bodyAction.id_motif = '');
      bodyAction.id_statut = this.lastActionId;
      for (const i of this.informationsContrat.contrat.laison_contrat) {
        try {
          bodyAction.id_affaire = i.id;
          const response = await this.apiOpportunitService.updateaffaireAction(bodyAction).toPromise();
          this.listSuccessAction.push(response.message);
        } catch (error) {
          this.listErrorAction.push(error.message);
        }
      }

      this.recapAlertAction(nbAffaire, this.listSuccessAction, this.listErrorAction);
    } else {
      this.Actualise();
    }
    this.step = false;
  }

  async alertWarnningMotif(nbAffaire, id_motif) {
    let message: string;
    if (nbAffaire === 1) {
      message = this.alerts.yavoir + this.alerts.uneAffaire + this.alerts.wantUpdateAffaire;
    } else {
      message = this.alerts.yavoir + nbAffaire + this.alerts.affaires + this.alerts.wantUpdateAffaire;
    }

    const result = await swal.fire({
      title: this.alerts.affaireLiee,
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.buttons.Ignorer,
      confirmButtonText: this.alerts.Oui,
    });

    if (result.isConfirmed) {
      const BodyUpdatedMotif: MotifAffaire = new MotifAffaire();
      BodyUpdatedMotif.id_motif = id_motif;
      for (const i of this.informationsContrat.contrat.laison_contrat) {
        BodyUpdatedMotif.id_affaire = i.id;
        try {
          const response = await this.apiAffairesService.updateEtatDossierMotif(BodyUpdatedMotif).toPromise();
          this.listSuccessAction2.push(response.message);
        } catch (error) {
          this.listErrorAction2.push(error.message);
        }
      }
      this.recapAlertAction(nbAffaire, this.listSuccessAction2, this.listErrorAction2);
    } else {
      this.Actualise();
    }
    this.step = false;
  }

  recapAlertAction(nbAffaire, nbAffaireSecces, nbAffaireFailed) {
    const Message = `<h4> ${this.alerts.totalNumberAffaire} <b> 
      ${nbAffaire} 
      </b> </h4><p style='margin-top:12px;text-align: initial;margin-left: 44px;'> <img src='/assets/icons/check-circle.svg' > 
      ${nbAffaireSecces.length} 
       ${this.alerts.affairesUpdated}</p> <p style='text-align: initial;margin-left: 44px;'> <img src='/assets/icons/times-icon.svg'> 
      ${nbAffaireFailed.length} 
       ${this.alerts.affaireNotTreated}</p> `;
    swal
      .fire({
        html: Message,
        icon: 'info',
        cancelButtonColor: '#138eb6',
        focusConfirm: true,
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: this.buttons.fermer,
      })
      .then((result) => {
        this.listErrorAction = [];
        this.listSuccessAction = [];
        this.Actualise();
        this.ngOnInit();
      });
  }

  alertWarning(title, invalidElements: any[]) {
    let listElementsAsString = '';
    invalidElements.forEach((element) => {
      if (typeof element.name === 'string')
        listElementsAsString =
          listElementsAsString +
          '<li style="list-style-type: none; font-size: 14px">' +
          element.name +
          ' : <u>' +
          element.value +
          '</u></li>';
    });
    swal.fire({
      title: title,
      icon: 'warning',
      html: '<ul class="p-0">' + listElementsAsString + '</ul>',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#e75e5e',
    });
  }

  alertWarning2(title, invalidElements: TypeErrorAffaire) {
    let listElementsAsString = '';
    /*     invalidElements.forEach((element) => {
          if (typeof element === 'string')
            listElementsAsString =
              listElementsAsString +
              '<li style="list-style-type: none; font-size: 14px">' +
              ' <u>' + element + '</u></li>';
        }); */
    listElementsAsString =
      '<li style="list-style-type: none; font-size: 14px">' +
      ' <u>' +
      invalidElements.info_affaire +
      '</u>' +
      ' <u>' +
      invalidElements.info_banc +
      '</u>' +
      ' <u>' +
      invalidElements.info_specifique +
      '</u>' +
      ' <u>' +
      invalidElements.objet_assure +
      '</u>' +
      ' <u>' +
      invalidElements.entreprise +
      '</u>' +
      ' <u>' +
      invalidElements.garantie_affaire +
      '</u>' +
      ' <u>' +
      invalidElements.infoDoc +
      '</u>' +
      '</li>';
    swal
      .fire({
        title: title,
        icon: 'warning',
        html: '<ul class="p-0">' + listElementsAsString + '</ul>',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: this.buttons.fermer,
        confirmButtonColor: '#e75e5e',
      })
      .then((result) => {
        // this.listError = [];
      });
  }

  alertError(data) {
    swal.fire({
      title: this.alerts.error,
      text: data,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      allowOutsideClick: false,
      confirmButtonColor: '#d53a3a',
    });
  }

  alertErrorAction(data, err) {
    swal
      .fire({
        title: this.alerts.error,
        text: err,
        icon: 'error',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: this.buttons.fermer,
        allowOutsideClick: false,
        confirmButtonColor: '#d53a3a',
      })
      .then(() => {
        //  this.subStatusList=[];
        this.step = false;
        this.removeModalBackdrop();
      });
  }

  private removeModalBackdrop() {
    const backdropElements = document.querySelectorAll('.modal-backdrop.fade.show');
    backdropElements.forEach((element) => {
      element.classList.remove('modal-backdrop', 'fade', 'show');
    });
    document.body.style.overflow = 'auto';
  }

  errorInfo() {
    swal.fire({
      title: this.alerts.noModif,
      confirmButtonText: this.buttons.annuler,
      icon: 'info',
    });
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  scroll(el: string) {
    const element = document.getElementById(el);
    const offset = 120;
    const bodyRect = document.body.getBoundingClientRect().top;
    if (element != null) {
      const elementRect = element.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }

  Actualise() {
    window.location.reload();
  }

  getProspectDoublon(event: any) {
    if (event.target.checked === true) {
      this.idSelected = event.target.value;
      this.ListIdProspect[this.item] = this.idSelected;
      this.item++;
    } else {
      this.idSelected = event.target.value;
      this.deleteItem(this.idSelected);
      this.item--;
    }
  }

  deleteItem(element) {
    const index: number = this.ListIdProspect.indexOf(element);
    if (index !== -1) {
      this.ListIdProspect.splice(index, 1);
    }
  }

  setFussionProspectDoublon() {
    this.apiProspectsService.fussionProspectDoublon(this.ListIdProspect).subscribe(
      () => {
        this.ngOnInit();
        this.alertSuccess(this.alerts.ProspectFussionner);
      },
      () => {
        this.alertError(this.alerts.error);
      }
    );
  }

  ngOnDestroy(): void {
    this.apiOpportunitService.formdataaffaircomp = null;
    this.apiOpportunitService.formdataaffairspecif = null;
    if (!this.isListe) {
      localStorage.removeItem('filtreAffaire');
      localStorage.removeItem('page');
      localStorage.removeItem('sort_field');
      localStorage.removeItem('sort_type');
      localStorage.removeItem('sSearch');
    }
    this.validationService.resetFieldValidity();
  }

  /**
   * Permets de retourner à la liste
   */
  returnToList(): void {
    this.isListe = true;
    const savedFilter = localStorage.getItem('savedFilter');
    const savedCollection = localStorage.getItem('savedCollection');
    const queryParams = {
      filter: savedFilter,
      collection: savedCollection,
    };
    this.router.navigate(['affaires'], { queryParams: queryParams });
  }

  getFormInfoSpecifique(form: any): void {
    this.formInformationSpecifique = form;
  }

  validateFormInformationSpecifique(): boolean {
    if (this.formInformationSpecifique !== undefined) {
      const invalidElements = [];
      for (const [key, value] of Object.entries(this.formInformationSpecifique.controls)) {
        const element: any = value;
        if (element.status === 'INVALID') {
          invalidElements.push({ name: key, value: element.value });
        }
      }
      if (this.formInformationSpecifique.status === 'INVALID') {
        this.alertWarning(this.alerts.infoCompLigneProduitInvalid, invalidElements);
        this.apiAdminBpmServices.sharedFormIsValid = false;
        this.infoSpecifique = null;
        this.infoSpecifiqueUpdated = false;
        return false;
      } else {
        return true;
      }
    }
    return true;
  }

  getAffaireGarantie() {
    this.apiAffairesService.getAffaireGarantieByID(this.affaire_id).subscribe((response) => {
      this.listeGarantieAffaire = response;
    });
  }

  addAffaireGarantie(body: any) {
    this.apiAffairesService.updateAffaireGarantie(body).subscribe(
      (response) => {
        this.alertSuccess(this.alerts.affaireGarantieUpdated);
      },
      (error) => {
        this.alertError(error.error.message);
      }
    );
  }

  onUpdateGaranties($event: any) {
    this.bodyGarantie = $event;
    this.upadateGarantieAffaire = true;
    this.listError.garantie_affaire = '';
  }

  updateobjetAssure($event: boolean) {
    this.upadateobjetAssureAffaire = true;
    this.listError.objet_assure = '';
  }

  statutappear() {
    this.updateStatusaffaire = true;
    this.updateStatus = false;
    this.appear = false;
  }

  appearModel: boolean = false;
  resetStatus() {
    this.step = false;
    this.appear = false;
    this.appearModel = false;
    this.show_massage = false;
    this.liste_motif = [];
  }

  retourSubmit() {
    this.appear = true;
  }

  motif: any;
  commentaire: any;
  MotifControl = new FormControl('', Validators.required);
  creerAvenant() {
    const bodyAvenant: Avenant = new Avenant();
    bodyAvenant.id_affaire = this.affaire_id;
    this.MotifControl.value ? (bodyAvenant.motif = this.MotifControl.value.toString()) : (bodyAvenant.motif = '');
    this.commentaire ? (bodyAvenant.commentaire = this.commentaire) : (bodyAvenant.commentaire = '');
    // bodyAvenant.motif= this.MotifControl.value;
    // bodyAvenant.commentaire= this.commentaire;
    if (!this.MotifControl.value) {
      swal.fire({
        title: this.alerts.error,
        text: this.alerts.motifOblig,
        icon: 'error',
        allowOutsideClick: true,
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: this.buttons.fermer,
        confirmButtonColor: '#d53a3a',
      });
      //  this.alertError("Motif obligatoire");
    } else {
      this.addAvenantLoader = true;
      if (this.informationsContrat.contrat.gamme?.url_tarification) {
        this.apiAffairesService.CreerAvenant(bodyAvenant).subscribe(
          (response) => {
            this.addAvenantLoader = false;
            window.open(
              this.informationsContrat.contrat.gamme.url_tarification +
                '?token=' +
                response.token +
                '&id_prospect=' +
                response.id_prospect +
                '&id_affaire=' +
                response.id_affaire +
                '&id_opp=' +
                this.informationsContrat.contrat.id_opp,
              '_blank'
            );
            this.MotifControl.setValue('');
            this.commentaire = '';
            this.Actualise();
          },
          (error) => {
            this.addAvenantLoader = false;
            this.alertError(error.error.message);
            this.MotifControl.setValue('');
            this.commentaire = '';
          }
        );
      } else {
        this.apiAffairesService.CreerAvenantLocal(bodyAvenant).subscribe(
          (response) => {
            this.addAvenantLoader = false;
            window.open('/affaires/details/' + response.new_affaire, '_blank');
            this.MotifControl.setValue('');
            this.commentaire = '';
            this.Actualise();
          },
          (error) => {
            this.addAvenantLoader = false;
            this.alertError(error.error.message);
            this.MotifControl.setValue('');
            this.commentaire = '';
          }
        );
      }
    }
  }

  getListeMotifAvenant() {
    this.apiAffairesService.getlistMotifAvenant(this.affaire_id).subscribe((response) => {
      this.listMotif = response.motif;
    });
  }

  showModal = false;
  openModalOrPopup() {
    this.showModal = true;
    this.getListeMotifAvenant();
    // if (this.informationsContrat.contrat.gamme?.url_tarification) {

    // this.showModal = false;
    // const nomGamme = this.informationsContrat.contrat.gamme?.nom;
    // const message = `${this.alerts.verifyLienTarif} "<b>${nomGamme}</b>"`;

    // swal.fire({
    //   html: message,
    //   icon: 'warning',
    //   showConfirmButton: true,
    //   showCloseButton: true,
    //   confirmButtonText: this.buttons.fermer,
    //   confirmButtonColor: '#d53a3a',
    //   customClass: {
    //     popup: 'border-radius-0',
    //   },
    // });

    // this.alertError()
    // }
  }

  NaviagateToOppoDetials(id: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/opportunities/details/' + id + '/prospect']));
    window.open(url, '_blank');
    //  this.router.navigate(['/opportunities/details/' + id + '/prospect']);
  }

  liste_motif: Motif[] = [];
  loaderMotif: boolean = true;
  getMotifByStatut(id) {
    this.apiAdminBpmServices.getListMotifStatut(id).subscribe((response) => {
      this.liste_motif = response;
      this.loaderMotif = false;
    });
  }

  list_motif_statut_actuel: Motif[] = [];
  loadermotifActuel: boolean = false;
  showModalMotif: boolean = true;
  getMotifByStatutActuel() {
    this.loadermotifActuel = true;
    this.showModalMotif = true;
    this.apiAdminBpmServices.getListMotifStatut(this.informationsContrat.contrat.etat_dossier_id).subscribe(
      (response) => {
        this.list_motif_statut_actuel = response.Motifs.filter(
          (element) => element.id !== this.informationsContrat.contrat.motif.id
        );

        this.loadermotifActuel = false;
      },
      () => {
        this.loadermotifActuel = false;
      }
    );
  }

  updateMotif(id_motif) {
    const BodyUpdatedMotif: MotifAffaire = new MotifAffaire();
    BodyUpdatedMotif.id_affaire = this.affaire_id;
    BodyUpdatedMotif.id_motif = id_motif;
    this.showModalMotif = false;
    this.apiAffairesService.updateEtatDossierMotif(BodyUpdatedMotif).subscribe(
      (response) => {
        if (this.informationsContrat.contrat.laison_contrat.length > 0) {
          const x = this.informationsContrat.contrat.laison_contrat.length;
          this.alertWarnningMotif(x, id_motif);
          this.showModalMotif = false;
        } else {
          this.alertSuccess(this.alerts.updatedActionSucess);
          this.showModalMotif = false;
        }
        // this.alertSuccess('Action mise a jour avec succès')
        // let x = this.informationsContrat.contrat.laison_contrat.length

        // this.alertWarnningMotif(x ,id_motif);
        this.showModalMotif = false;
      },
      () => {
        this.alertErrorAction(this.alerts.error, this.alerts.badHappened);
        this.showModalMotif = false;
      }
    );
  }

  resetMotif() {
    this.list_motif_statut_actuel = [];
    this.showModalMotif = false;
  }

  expandFileName(name: string): string {
    if (name.length < 13) {
      return name;
    } else {
      return name.substring(0, 12) + '...';
    }
  }
}
